import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import ResponsiveSpacing from "../components/responsive-spacing"
import Garnish from "../components/garnish"
import SEO from "../components/seo"
import Layout from "../components/layout"
import SVG from "../components/svg"
import ButtonColorNav from "../components/bottom-color-nav"

const ContentStyled = styled.div`
  max-width: 840px;
  margin: 123px auto 100px auto;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }

  h1 {
    text-align: center;
    text-transform: uppercase;
    font-size: 9.2em;
    font-weight: 800;
    letter-spacing: 2px;

    margin: 0;
    font-family: "interstate-compressed";
    color: ${(p) => p.theme.DarkBlue};
    @media (max-width: 768px) {
      font-size: 6.1em;
    }
  }
  h2 {
    font-size: 2.4em;
    color: ${(p) => p.theme.Blue};
    margin: 10px 0;
  }
  p {
    padding: 0;
    margin: 20px 0;
    color: ${(p) => p.theme.DarkBlue};
  }
  ul {
    margin: 0;
    padding: 0 0 0 30px;
  }
  li {
    font-size: 1.4em;
    padding: 0;
    margin: 10px 0 10px 0;
    color: ${(p) => p.theme.DarkBlue};
  }
  a {
    color: ${(p) => p.theme.DarkBlue};
  }
  .center {
    text-align: center;
    padding: 10px 0 60px 0;
  }
`

const Content = ({ title, content, acf }) => {
  return (
    <ContentStyled>
      <h1
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <div className="center">
        <SVG name="SWave" color={"orange"} />
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </ContentStyled>
  )
}

const TermsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { slug: { eq: "terms-page" } }) {
        edges {
          node {
            content
            title
            acf {
              search_title
              share_headline
              search_description
              share_comment
              share_image {
                source_url
              }
              margin_images {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 178, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const fields = data.allWordpressPage.edges[0].node
  const { title, content, acf } = fields
  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = acf

  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <ResponsiveSpacing>
        <Content title={title} acf={acf} content={content} />{" "}
      </ResponsiveSpacing>

      {acf.margin_images.map((a, i) => {
        return (
          <Garnish
            garnish={a}
            pSpeed={-0.4}
            top={600 * i + 800}
            rotation={0}
            rSpeed={[-0.03, 0.03][i % 2]}
            pos={["left", "right"][i % 2]}
          />
        )
      })}
      <ButtonColorNav />
    </Layout>
  )
}

export default TermsPage
